<template>
  <div class="reddit-index">
    <div class="rediit-head">
      <div class="head-item" v-for="(item, i) in headArr" :key="i">
        <img :src="item.img" alt="" />
        <div class="text-box">
          <div v-if="!item.bold" class="item-text">
            {{ item.memo1 }}
          </div>
          <div v-else class="item-text">
            <span>{{ item.memo1 }}</span>
            {{ item.measurement }}
          </div>
          <div class="item-text">
            {{ item.memo2 }}
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="reddit-content">
      <div>
        <div class="index-title">监控任务统计</div>
        <div id="echarts"></div>
      </div>
      <div>
        <div class="index-title">
          <div>最新社区方案</div>
          <span @click="goPath('/monitor/subreddit')">更多>></span>
        </div>
        <el-table
          :data="communityList"
          header-row-class-name="tableheader"
          style="width: 100%;margin-top:10px"
          height="280px"
          :row-style="{ height: 0 + 'px' }"
          :cell-style="{ padding: '5px 0' }"
        >
          <el-table-column label="头像" width="50">
            <template slot-scope="scope">
              <img
                :src="
                  scope.row.redditSubreddit
                    ? scope.row.redditSubreddit.iconImg
                    : require('@/assets/imgs/r.jpg')
                "
                alt=""
                :onerror="shequPic"
                style="width:30px; height:30px;"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="subredditName"
            label="社区名称"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column prop="state" label="状态">
            <template slot-scope="scope">
              <el-tag
                size="mini"
                :type="
                  items.hasOwnProperty(scope.row.state) === true
                    ? items[scope.row.state].type
                    : 'danger'
                "
                effect="dark"
              >
                {{
                  items.hasOwnProperty(scope.row.state) === true
                    ? items[scope.row.state].label
                    : '获取失败'
                }}
              </el-tag>
            </template>
          </el-table-column>

          <el-table-column prop="createTime" label="创建时间"></el-table-column>
        </el-table>
      </div>
      <div>
        <div class="index-title">
          <div>最新账号方案</div>
          <span @click="goPath('/monitor/user')">更多>></span>
        </div>
        <el-table
          :data="accountList"
          header-row-class-name="tableheader"
          style="width: 100%;margin-top:10px"
          height="280px"
          :row-style="{ height: 0 + 'px' }"
          :cell-style="{ padding: '5px 0' }"
        >
          <el-table-column label="头像" width="50">
            <template slot-scope="scope">
              <img
                :src="
                  scope.row.redditUser
                    ? scope.row.redditUser.iconImg
                    : require('@/assets/imgs/r.jpg')
                "
                alt=""
                :onerror="shequPic"
                style="width:30px; height:30px;"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="redditUserName"
            label="社区用户名"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column prop="state" label="状态">
            <template slot-scope="scope">
              <el-tag
                size="mini"
                :type="
                  items.hasOwnProperty(scope.row.state) === true
                    ? items[scope.row.state].type
                    : 'danger'
                "
                effect="dark"
              >
                {{
                  items.hasOwnProperty(scope.row.state) === true
                    ? items[scope.row.state].label
                    : '获取失败'
                }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间"></el-table-column>
        </el-table>
      </div>
    </div>
    <div class="reddit-foot">
      <div class="foot-left">
        <div class="index-title">
          <div>社区数据</div>
          <span @click="goPath('/search/subreddits')">更多>></span>
        </div>
        <el-table
          :data="communityArr"
          header-row-class-name="tableheader"
          style="width: 100%;margin-top:10px"
          :height="windowsHeight - 625"
          :row-style="{ height: 0 + 'px' }"
          :cell-style="{ padding: '5px 0' }"
        >
          <el-table-column label="头像" width="50">
            <template slot-scope="scope">
              <img
                class="table-cell-img"
                :src="scope.row.iconImg"
                alt=""
                :onerror="noPic"
              />
            </template>
          </el-table-column>

          <el-table-column
            prop="subredditName"
            label="社区名称"
            show-overflow-tooltip
          ></el-table-column>

          <el-table-column
            prop="title"
            label="标题"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="description"
            label="简介"
            show-overflow-tooltip
            width="400"
          ></el-table-column>
          <el-table-column
            prop="created"
            label="成立时间"
            width="150"
          ></el-table-column>
        </el-table>
      </div>
      <div class="foot-right">
        <div class="index-title">
          <div>用户数据</div>
          <span @click="goPath('/search/users')">更多>></span>
        </div>
        <el-table
          :data="userList"
          header-row-class-name="tableheader"
          style="width: 100%;margin-top:10px"
          :height="windowsHeight - 625"
          :row-style="{ height: 0 + 'px' }"
          :cell-style="{ padding: '5px 0' }"
        >
          <el-table-column label="头像" width="50">
            <template slot-scope="scope">
              <img
                class="table-cell-img"
                :src="scope.row.iconImg"
                alt=""
                :onerror="noPic"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="userName"
            label="用户名"
            show-overflow-tooltip
          ></el-table-column>

          <el-table-column
            prop="created"
            label="创建时间"
            show-overflow-tooltip
          ></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { getDashboardData, getMyMonitorTask } from '@/api/dashboard.js'
import {
  getSubredditMonitorTasks,
  getUserMonitorTasks
} from '../../api/monitor'
import { getSubredditsByQuery, getUsersByQuery } from '../../api/reddit'
export default {
  data() {
    return {
      noPic: 'this.src="' + require('@/assets/imgs/p.png') + '"',
      shequPic: 'this.src="' + require('@/assets/imgs/r.jpg') + '"',
      headArr: [
        {
          img: require('../../assets/imgs/t1.png'),
          memo1: '开通日期：2020-03-01',
          memo2: '开通日期：2020-03-01',
          bold: false
        },
        {
          img: require('../../assets/imgs/t4.png'),
          memo1: 0,
          memo2: '关键词方案',
          bold: true,
          measurement: ''
        },
        {
          img: require('../../assets/imgs/t3.png'),
          memo1: 0,
          memo2: '用户方案',
          bold: true,
          measurement: ''
        },
        {
          img: require('../../assets/imgs/t5.png'),
          memo1: 0,
          memo2: '社区数据',
          bold: true,
          measurement: ''
        },
        {
          img: require('../../assets/imgs/t6.png'),
          memo1: '0',
          memo2: '用户数据',
          bold: true,
          measurement: ''
        },
        {
          img: require('../../assets/imgs/t7.png'),
          memo1: '0',
          memo2: '帖子数据',
          bold: true,
          measurement: ''
        },
        {
          img: require('../../assets/imgs/t8.png'),
          memo1: '0',
          memo2: '评论数据',
          bold: true,
          measurement: ''
        }
      ],
      communityList: [],
      communityArr: [],
      accountList: [],
      userList: [],
      items: {
        1: { type: 'success', label: '监控中' },
        0: { type: 'warning', label: '停止监控' },
        '-1': { type: 'danger', label: '名称错误' }
      }
    }
  },
  computed: {
    windowsHeight() {
      return this.$store.state.windowData.height
    }
  },
  mounted() {
    // 获取头部6个数据
    this.getHead()
    // 获取折线图的数据
    this.getEcharts()
    // 社区方案
    this.communityData()
    this.communityRetrieval()
    // 账号方案
    this.accountScheme()
    this.getUserData()
  },
  methods: {
    goPath(path) {
      this.$router.push(path)
    },
    // 社区方案
    communityData() {
      let obj = {
        pageIndex: 1,
        pageSize: 10
      }

      getSubredditMonitorTasks(obj).then(res => {
        // console.log(res)
        this.communityList = res.items
      })
    },
    communityRetrieval() {
      let obj = {
        pageIndex: 1,
        pageSize: 10
      }
      getSubredditsByQuery(obj).then(data => {
        this.communityArr = data.items
      })
    },
    // 账号方案
    accountScheme() {
      let obj = {
        pageIndex: 1,
        pageSize: 10
      }
      getUserMonitorTasks(obj).then(res => {
        this.accountList = res.items
      })
    },
    getUserData() {
      let obj = {
        pageIndex: 1,
        pageSize: 10
      }
      getUsersByQuery(obj).then(res => {
        this.userList = res.items
      })
    },
    getHead() {
      getDashboardData().then(res => {
        this.headArr[0].memo1 =
          '开通日期：' +
          this.$store.state.user.userInfo.create_time.substring(0, 10)
        this.headArr[0].memo2 =
          '结束日期：' +
          this.$store.state.user.userInfo.endTime.substring(0, 10)
        this.headArr[1].memo1 = res.keywordMonitorCount
        this.headArr[2].memo1 = res.userMonitorCount
        this.headArr[3].memo1 = res.redditSubredditCount
        this.headArr[4].memo1 = res.redditUserCount
        this.headArr[5].memo1 = res.redditPostCount
        this.headArr[6].memo1 = res.redditCommentCount
      })
    },
    getEcharts() {
      getMyMonitorTask().then(res => {
        this.getEchartsLine('echarts', res.xAxis, res.yAxis)
      })
    },
    getEchartsLine(dom, dataX, dataY) {
      const chart = echarts.init(document.getElementById(dom))
      const option = {
        grid: {
          x: 50,
          y: 40,
          x2: 30,
          y2: 20,
          borderWidth: 10
        },
        xAxis: {
          type: 'category',
          data: dataX,
          boundaryGap: false
        },
        yAxis: {
          type: 'value'
        },
        tooltip: {
          // 提示框组件
          trigger: 'item', // 触发类型,'item'数据项图形触发，主要在散点图，饼图等无类目轴的图表中使用。 'axis'坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用。
          triggerOn: 'mousemove', // 提示框触发的条件,'mousemove'鼠标移动时触发。'click'鼠标点击时触发。'mousemove|click'同时鼠标移动和点击时触发。'none'不在 'mousemove' 或 'click' 时触发
          showContent: true, // 是否显示提示框浮层
          alwaysShowContent: false, // 是否永远显示提示框内容
          showDelay: 0, // 浮层显示的延迟，单位为 ms
          hideDelay: 100, // 浮层隐藏的延迟，单位为 ms
          enterable: false, // 鼠标是否可进入提示框浮层中
          confine: false, // 是否将 tooltip 框限制在图表的区域内
          transitionDuration: 0.4, // 提示框浮层的移动动画过渡时间，单位是 s,设置为 0 的时候会紧跟着鼠标移动
          // position: ["50%", "50%"], //提示框浮层的位置，默认不设置时位置会跟随鼠标的位置,[10, 10],回掉函数，inside鼠标所在图形的内部中心位置，top、left、bottom、right鼠标所在图形上侧，左侧，下侧，右侧，
          // formatter: "{b0}: {c0}<br />{b1}: {c1}", //提示框浮层内容格式器，支持字符串模板和回调函数两种形式,模板变量有 {a}, {b}，{c}，{d}，{e}，分别表示系列名，数据名，数据值等
          backgroundColor: 'transparent', // 标题背景色
          borderColor: '#ccc', // 边框颜色
          borderWidth: 0, // 边框线宽
          padding: 5 // 图例内边距，单位px  5  [5, 10]  [5,10,5,10]
        },

        series: [
          {
            data: dataY,
            type: 'line',
            smooth: true,
            lineStyle: {
              width: '4',
              color: '#f2597f'
            },
            // symbol: "none",
            color: '#f2597f',
            symbolSize: 10,
            // label: { show: true },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#f2597fe7'
                },
                {
                  offset: 1,
                  color: '#f2597f2d'
                }
              ])
            }
          }
        ]
      }
      window.onresize = function() {
        chart.resize()
      }
      chart.setOption(option)
    }
  }
}
</script>

<style lang="scss" scoped>
.reddit-index {
  width: 100%;
  height: calc(100vh - 50px);
  padding: 10px 20px;
  background: rgb(240, 242, 245);

  .rediit-head {
    width: 100%;
    background: white;
    border-radius: 5px;
    display: flex;
    box-shadow: 0 1px 3px 0 #d8d9d9;
    .head-item {
      width: calc(100% / 7 - 1px);
      height: 131px;
      border-right: 1px solid #f2f4f8;
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
      &:nth-last-child(1) {
        border-right: none;
      }
      img {
        object-fit: cover;
        align-self: center;
      }
      .text-box {
        align-self: center;
        margin-top: 14px;
        text-align: center;
        .item-text {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          span {
            font-size: 22px;
          }
        }
      }
    }
  }
  .reddit-content {
    height: 337px;
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    > div {
      width: 33%;
      height: 100%;
      background: #ffffff;
      border-radius: 5px;
      padding: 5px;
      overflow: hidden;
      box-shadow: 0 1px 3px 0 #d8d9d9;
    }
    #echarts {
      width: 100%;
      height: calc(100% - 40px);
    }
  }
  .reddit-foot {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;

    > div {
      background: white;
      border-radius: 5px;
      height: 100%;
      padding: 5px;
    }
    .foot-left {
      width: 66.5%;
      box-shadow: 0 1px 3px 0 #d8d9d9;
    }
    .foot-right {
      width: 33%;
      box-shadow: 0 1px 3px 0 #d8d9d9;
    }
  }
  .index-title {
    display: flex;
    justify-content: space-between;
    text-indent: 9px;
    font-size: 14px;
    font-weight: 400;
    color: #4a4a4a;
    line-height: 24px;
    border-bottom: 1px solid #f4f4f4;
    padding: 5px 0;
    font-weight: 600;
    span {
      color: #7c7b7b;
      cursor: pointer;
      &:hover {
        color: rgb(11, 106, 214);
      }
    }
  }
  // ::v-deep .el-table__header .el-table__cell {
  //   background: rgb(243, 247, 249);
  // }
  ::v-deep .tableheader th {
    background: rgb(243, 247, 249);
    height: 35px;
    padding: 0;
  }
  .table-cell-img {
    width: 28px;
    height: 28px;
    object-fit: cover;
    vertical-align: middle;
  }
}
</style>
